/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  display: block;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  display: block;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.3);
  border-radius: 999px;
  border: 1px solid transparent;
  background-clip: content-box;
  min-height: 40px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.5);
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.2) transparent;
  scrollbar-gutter: stable;
}

/* Dark mode adjustments */
.dark .custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

.dark .custom-scrollbar {
  scrollbar-color: rgba(255, 255, 255, 0.08) transparent;
}

/* Add new overlay scrollbar variant */
.custom-scrollbar-overlay::-webkit-scrollbar {
  width: 6px;
  display: none; /* Hidden by default */
}

.custom-scrollbar-overlay:hover::-webkit-scrollbar {
  display: block; /* Show on hover */
}

.custom-scrollbar-overlay::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}

.custom-scrollbar-overlay::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 4px;
  min-height: 40px;
}

.custom-scrollbar-overlay::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.7);
}

/* For Firefox */
.custom-scrollbar-overlay {
  scrollbar-width: none; /* Hidden by default */
  scrollbar-gutter: auto; /* Don't reserve space */
}

.custom-scrollbar-overlay:hover {
  scrollbar-width: thin; /* Show on hover */
}

/* Dark mode adjustments for overlay variant */
.dark .custom-scrollbar-overlay::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

.dark .custom-scrollbar-overlay:hover {
  scrollbar-color: rgba(156, 163, 175, 0.5) rgba(255, 255, 255, 0.05);
}

/* Add ChatInterface specific scrollbar variant */
.chat-scrollbar-overlay {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(156, 163, 175, 0.5) transparent; /* For Firefox */
}

.chat-scrollbar-overlay::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  display: block !important; /* Force scrollbar to always show */
}

.chat-scrollbar-overlay::-webkit-scrollbar-track {
  background: transparent;
}

.chat-scrollbar-overlay::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 4px;
  border: 2px solid transparent;
}

/* Dark mode styles */
.dark .chat-scrollbar-overlay::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.3);
}

/* Always show scrollbar styles */
.always-show-scrollbar {
  overflow-y: scroll !important;
}

/* Add popup scrollbar variant */
.popup-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.3) transparent;
}

.popup-scrollbar::-webkit-scrollbar {
  width: 6px;
  display: block;
}

.popup-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.popup-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.3);
  border-radius: 4px;
  min-height: 40px;
}

.popup-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.5);
}

/* Dark mode adjustments for popup scrollbar */
.dark .popup-scrollbar {
  scrollbar-color: rgba(156, 163, 175, 0.2) transparent;
}

.dark .popup-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.2);
}

.dark .popup-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.3);
}

/* Add no-scrollbar class for completely hidden scrollbars */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

/* Document Tasks specific scrollbar style */
.document-tasks-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.2) transparent;
}

.document-tasks-scrollbar::-webkit-scrollbar {
  width: 4px;
  display: block;
}

.document-tasks-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.document-tasks-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.2);
  border-radius: 4px;
  min-height: 40px;
  transition: background-color 0.2s ease;
}

.document-tasks-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.3);
}

/* Dark mode adjustments for document tasks scrollbar */
.dark .document-tasks-scrollbar {
  scrollbar-color: rgba(156, 163, 175, 0.1) transparent;
}

.dark .document-tasks-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.1);
}

.dark .document-tasks-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.2);
}

/* Add hover effect for the entire scrollable area */
.document-tasks-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.3);
}

.dark .document-tasks-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.2);
}

/* Add these styles if they don't exist already */

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.3);
  border-radius: 999px;
  border: 1px solid transparent;
  background-clip: content-box;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.5);
}

/* Dark mode styles */
.dark .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark .custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.2) transparent;
}

.dark .custom-scrollbar {
  scrollbar-color: rgba(255, 255, 255, 0.08) transparent;
}