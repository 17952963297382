@keyframes copyFloatAway {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 0.7;
  }
  100% {
    transform: translate(0, -20px) scale(1.2);
    opacity: 0;
  }
}

.copy-animation-float {
  animation: copyFloatAway 1s ease-out forwards;
}

/* Keep the existing animation for the green dot if you want to use it as well */
@keyframes copySuccess {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.copy-animation-improved {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(59, 130, 246, 0.2);
  border-radius: 50%;
  animation: copyAnimation 0.5s ease-out;
}

@keyframes copyAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}