.App {
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -16px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 16px; /* gutter size */
  background-clip: padding-box;
}

.notion-card {
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, 
              rgba(15, 15, 15, 0.1) 0px 2px 4px;
  transition: all 0.3s ease;
}
/* Add this CSS */
.my-masonry-grid_column {
  padding-left: 16px; /* gutter size */
  background-clip: padding-box;
}
.my-masonry-grid_column > div {
  margin-bottom: 16px; /* space between items */
}

.icon-padding {
  padding: 0px; /* Adjust as needed */
}

.top-nav-bar {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.task-manager {
  background-color: #f3f4f6;
  min-height: 100vh;
}

.main-content {
  background-color: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.task-board {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .task-board {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .task-board {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .task-board {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.task-column {
  background-color: #f9fafb;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.task-item {
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s;
}

.task-item:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.filter-menu {
  position: relative;
  display: inline-block;
  text-align: left;
}

.filter-button {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
}

.filter-button:hover {
  background-color: #f9fafb;
}

.filter-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #f3f4f6, 0 0 0 4px #3b82f6;
}

.pomodoro-timer {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.chart-container {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  margin-bottom: 2rem;
}

/* Add more custom styles as needed */

.sidebar-subitem-icons {
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.sidebar-subitem-icons.visible {
  opacity: 1;
  visibility: visible;
}

/* Add this new class */
.sidebar-item-wrapper {
  position: relative;
}

/* Update this class */
.sidebar-subitem-icons {
  position: absolute;
  left: 100%;
  top: 0;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 20;
  padding: 0.5rem;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in {
  animation: slide-in 0.3s ease-out forwards;
}

@keyframes slide-out {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.animate-slide-out {
  animation: slide-out 0.3s ease-in forwards;
}

.notification-stack {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 50;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 24rem;
}

.notification-enter {
  transform: translateX(100%);
  opacity: 0;
}

.notification-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 300ms ease-out;
}

.notification-exit {
  transform: translateX(0);
  opacity: 1;
}

.notification-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: all 300ms ease-in;
}

/* Add this to prevent white flash */
html.dark {
  background-color: #121212;
}

html.dark body {
  background-color: #121212;
}

/* Ensure smooth transition when theme changes */
html {
  transition: background-color 0.2s ease;
}

body {
  transition: background-color 0.2s ease;
}
