/* Settings specific scrollbar styles */
.settings-scroll-area::-webkit-scrollbar {
  width: 6px;
}

.settings-scroll-area::-webkit-scrollbar-track {
  background: transparent;
}

.settings-scroll-area::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.3);
  border-radius: 20px;
  border: transparent;
}

.settings-scroll-area {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.3) transparent;
}

/* Dark mode */
.dark .settings-scroll-area::-webkit-scrollbar-thumb {
  background-color: rgba(51, 65, 85, 0.5);
}

/* Expandable content scrollbar */
.settings-expandable-content::-webkit-scrollbar {
  width: 4px;
}

.settings-expandable-content::-webkit-scrollbar-track {
  background: transparent;
}

.settings-expandable-content::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.2);
  border-radius: 20px;
  opacity: 0;
  transition: opacity 0.2s;
}

.settings-expandable-content:hover::-webkit-scrollbar-thumb {
  opacity: 1;
}

/* Dark mode for expandable content */
.dark .settings-expandable-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark .settings-expandable-content:hover::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.15);
}

/* Firefox */
.settings-expandable-content {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.settings-expandable-content:hover {
  scrollbar-color: rgba(156, 163, 175, 0.2) transparent;
}

.dark .settings-expandable-content:hover {
  scrollbar-color: rgba(255, 255, 255, 0.1) transparent;
}

/* Add smooth transitions */
.settings-expandable-content {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Enhanced input fields */
input, textarea {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

input:focus, textarea:focus {
  transform: translateY(-1px);
} 