/* Replace the @tailwind directives with imports */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Rest of your existing imports */
@import '@fontsource/inter/300.css';
@import '@fontsource/inter/400.css';
@import '@fontsource/inter/500.css';
@import '@fontsource/inter/700.css';
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif:wght@400;700&display=swap');

/* Add these styles to fix scrollbar issues */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed; /* Prevent body from scrolling */
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add these new styles for the invisible scrollbar */
.invisible-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.invisible-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none; /* Chrome, Safari, and Opera */
}

/* Add any custom styles here */
.prose {
  max-width: 100%;
}

/* Add this new style for a subtle hover effect on editable fields */
.bg-gray-50:hover {
  background-color: #f9fafb;
}

.bg-gray-100:hover {
  background-color: #f3f4f6;
}

/* Add these new styles to remove the default select arrow */
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: none;
}

select::-ms-expand {
  display: none;
}

/* Ensure the custom arrow is visible */
.custom-select-wrapper {
  position: relative;
}

.custom-select-wrapper::after {
  content: '▼';
  font-size: 0.8em;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Custom select styles */
.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: none;
}

.custom-select::-ms-expand {
  display: none;
}

/* Remove default arrow in IE */
select::-ms-expand {
  display: none;
}

/* Remove default arrow in Firefox */
select {
  text-indent: 0.01px;
  text-overflow: '';
}

/* Notion-inspired select styles */
select {
  cursor: pointer;
}

select:focus {
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
}

/* Custom scrollbar for Webkit browsers */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Ensure text is not selectable in the dropdown */
.select-none {
  user-select: none;
}

/* Add these styles at the end of your index.css file */

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* Increased from 6px to 10px */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 5px;
  border: 2px solid transparent;
  background-clip: content-box;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.7);
}

/* Remove message-content styles as they're now handled in markdown.css */
.message-content {
  white-space: normal;
  word-wrap: break-word;
}

.message-content p {
  /* Remove margin styles */
  margin: 0;
}

.message-content p:last-child {
  margin: 0;
}

@keyframes gradient-x {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.animate-gradient-x {
  animation: gradient-x 3s ease infinite;
  background-size: 200% 200%;
}

/* Updated copy animation styles */
@keyframes growCircle {
  0% {
    transform: scale(0);
    opacity: 0.7;
  }
  100% {
    transform: scale(2.5); /* Increased from 1.5 to 2.5 */
    opacity: 0;
  }
}

.copy-animation {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: rgba(59, 130, 246, 0.5); /* Blue color, adjust as needed */
  animation: growCircle 1.5s ease-out forwards; /* Increased duration from 1s to 1.5s */
}

/* Add this to your main CSS file */
.canvas-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
}

/* Update the glow animation to create a wrapping border effect */
@keyframes glow {
  0% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
    border-color: rgba(59, 130, 246, 0);
  }
  15% {
    /* Start from top-left */
    box-shadow: -2px -2px 0 0 rgba(59, 130, 246, 0.5);
    border-left: 2px solid rgba(59, 130, 246, 0.5);
    border-top: 2px solid rgba(59, 130, 246, 0.5);
  }
  35% {
    /* Continue to top-right */
    box-shadow: 2px -2px 0 0 rgba(59, 130, 246, 0.5);
    border-right: 2px solid rgba(59, 130, 246, 0.5);
    border-top: 2px solid rgba(59, 130, 246, 0.5);
  }
  65% {
    /* Continue to bottom-right */
    box-shadow: 2px 2px 0 0 rgba(59, 130, 246, 0.5);
    border-right: 2px solid rgba(59, 130, 246, 0.5);
    border-bottom: 2px solid rgba(59, 130, 246, 0.5);
  }
  85% {
    /* Complete the square at bottom-left */
    box-shadow: -2px 2px 0 0 rgba(59, 130, 246, 0.5);
    border-left: 2px solid rgba(59, 130, 246, 0.5);
    border-bottom: 2px solid rgba(59, 130, 246, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
    border-color: rgba(59, 130, 246, 0);
  }
}

.animate-glow {
  animation: glow 2s ease-in-out;
}

/* Remove the transition since we don't want size changes */
.linkedin-post-mockup {
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
  will-change: box-shadow, border-color;
}

/* Add these styles for the highlight animation */
@keyframes highlight-post {
  0% {
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
  }
  50% {
    box-shadow: 0 0 20px 10px rgba(59, 130, 246, 0.3);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0);
  }
}

.highlight-post {
  animation: highlight-post 2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  z-index: 100; /* Ensure highlighted post is above others */
}

/* Update the transition for canvas elements */
.canvas-post-transition {
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}


.rotatingText {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.rotatingText-enter {
  opacity: 0;
}

.rotatingText-enter-active {
  opacity: 1;
}

.rotatingText-exit {
  opacity: 1;
}

.rotatingText-exit-active {
  opacity: 0;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, 5px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/* Add this to your globals.css */
@keyframes saveGlow {
  0% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
  }
  50% {
    box-shadow: 0 0 8px 3px rgba(59, 130, 246, 0.2);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
  }
}

.save-animation {
  animation: saveGlow 1s ease-in-out;
}

/* For dark mode */
.dark .save-animation {
  animation: saveGlowDark 1s ease-in-out;
}

@keyframes saveGlowDark {
  0% {
    box-shadow: 0 0 0 0 rgba(148, 163, 184, 0);
  }
  50% {
    box-shadow: 0 0 8px 3px rgba(148, 163, 184, 0.2);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(148, 163, 184, 0);
  }
}


/* Ensure scrollbars are always visible */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, .5) transparent;
}

.linkedin-post-container {
  position: relative;
  overflow: visible !important;
  transform-style: preserve-3d;
  pointer-events: auto;
}

.linkedin-post-mockup {
  position: relative;
  overflow: visible !important;
  transform-style: preserve-3d;
  isolation: isolate;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: geometricPrecision;
  will-change: transform;
  image-rendering: high-quality;
  transition: none;
}

.editing-glow-light {
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(37, 99, 235, 0.1);
  margin: -1px;
}

.editing-glow-dark {
  background-color: #0f172a;
  box-shadow: 0 0 0 1px rgba(59, 130, 246, 0.2);
  margin: -1px;
}

/* Glow animation keyframes for light mode */
@keyframes pulse-light {
  0% {
    box-shadow: 0 0 15px rgba(37, 99, 235, 0.1),
                0 0 30px rgba(37, 99, 235, 0.05);
  }
  50% {
    box-shadow: 0 0 20px rgba(37, 99, 235, 0.15),
                0 0 40px rgba(37, 99, 235, 0.1);
  }
  100% {
    box-shadow: 0 0 15px rgba(37, 99, 235, 0.1),
                0 0 30px rgba(37, 99, 235, 0.05);
  }
}

/* Glow animation keyframes for dark mode */
@keyframes pulse-dark {
  0% {
    box-shadow: 0 0 15px rgba(59, 130, 246, 0.15),
                0 0 30px rgba(59, 130, 246, 0.1);
  }
  50% {
    box-shadow: 0 0 20px rgba(59, 130, 246, 0.2),
                0 0 40px rgba(59, 130, 246, 0.15);
  }
  100% {
    box-shadow: 0 0 15px rgba(59, 130, 246, 0.15),
                0 0 30px rgba(59, 130, 246, 0.1);
  }
}

/* Post highlight animation */
@keyframes highlight-post {
  0% {
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
  }
  50% {
    box-shadow: 0 0 20px 10px rgba(59, 130, 246, 0.3);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0);
  }
}

.highlight-post {
  animation: highlight-post 2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  z-index: 100;
}

/* Optimization styles for better rendering */
.zoom-quality {
  -webkit-font-smoothing: subpixel-antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
  font-kerning: normal !important;
  font-optical-sizing: auto !important;
  letter-spacing: normal !important;
  word-spacing: normal !important;
  backface-visibility: hidden !important;
  transform-style: flat !important;
  will-change: transform !important;
  image-rendering: high-quality !important;
  transform-origin: center center !important;
}

/* Force GPU acceleration for all elements */
* {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

/* Add these classes for better text rendering */
.zoom-quality {
  text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
  font-smooth: always;
}

/* Add a class for the transformed container */
.transform-quality {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  perspective: 1000px;
  will-change: transform;
  isolation: isolate;
}

/* Optional: Add this if you want to prevent text selection during zooming */
.no-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* Add these new classes for editing effects */
.editing-glow-light {
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05),
              0 2px 4px rgba(0, 0, 0, 0.05),
              0 12px 24px rgba(0, 0, 0, 0.05);
}

.editing-glow-dark {
  background-color: #0f172a;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05),
              0 2px 4px rgba(0, 0, 0, 0.2),
              0 12px 24px rgba(0, 0, 0, 0.2);
}

/* Add smooth transitions for the LinkedIn post */
.linkedin-post-mockup {
  transition: transform 0.3s ease-out,
              box-shadow 0.3s ease-out,
              border-color 0.2s ease-out;
  will-change: transform, box-shadow;
}

/* Add a subtle hover effect for the content area when editing */
.canvas-post-content-editor {
  position: relative;
  isolation: isolate;
  transition: none;
}

/* Remove focus effects */
.canvas-post-content-editor:focus-within {
  background: transparent;
  outline: none;
}

/* Remove the focus ring */
.canvas-post-content-editor:focus-within::after {
  display: none;
}

/* Add these CSS variables */
:root {
  --canvas-z-index: auto;
}

/* Add this class to your Canvas component's root element */
.canvas-container {
  z-index: var(--canvas-z-index);
  position: relative;
}

/* Add these new classes for high-quality transforms */
.transform-quality {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  will-change: transform;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

/* Improve text rendering during transforms */
.transform-quality * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Optimize performance for transformed elements */
.transform-quality img {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  transform: translateZ(0);
}

/* Add this to your existing custom-scrollbar class */
.custom-scrollbar {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translate3d(0,0,0);
}

/* Add a class for high DPI screens */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .transform-quality {
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(156, 163, 175, 0.5);
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(156, 163, 175, 0.7);
}

/* For dark mode */
.dark .custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(156, 163, 175, 0.3);
}

.dark .custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(156, 163, 175, 0.5);
}

@media (max-width: 768px) {
  html, body {
    height: 100%;
    height: -webkit-fill-available;
    overflow: auto;
    position: relative;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }

  #root {
    height: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow: auto;
  }

  .App {
    position: relative;
    height: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  /* Enable smooth scrolling for content areas */
  .overflow-scroll-mobile {
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch !important;
    overscroll-behavior-y: contain;
    height: auto;
    min-height: 100%;
    width: 100%;
    padding-bottom: calc(env(safe-area-inset-bottom) + 80px);
  }

  /* Add padding to the bottom of mobile scroll container */
  .mobile-scroll-container {
    height: auto;
    min-height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: contain;
    padding-bottom: calc(env(safe-area-inset-bottom) + 80px);
  }
}

/* Add these new styles for better mobile handling */
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

/* Ensure content is scrollable on mobile */
.mobile-scroll-container {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
}

/* Add this near the top of your CSS file */
@media screen and (max-width: 767px) {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  textarea {
    font-size: 16px !important; /* iOS won't zoom if font-size is 16px or larger */
  }
}

/* Update the existing input styles to ensure proper sizing on mobile */
.custom-input {
  font-size: 16px; /* Base size for mobile */
  @media screen and (min-width: 768px) {
    font-size: 14px; /* Smaller size for desktop */
  }
}

/* Add these styles for search inputs */
.expandable-search input {
  @media screen and (max-width: 767px) {
    font-size: 16px !important;
  }
}

/* Add these styles for chat inputs */
.chat-input textarea {
  @media screen and (max-width: 767px) {
    font-size: 16px !important;
  }
}

/* Add these rules for better text rendering */
.linkedin-post-mockup {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  font-optical-sizing: auto;
  /* Force GPU acceleration */
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
  /* Prevent text scaling issues */
  text-size-adjust: none;
}

/* Add a class for high-quality zoom */
.zoom-quality {
  image-rendering: high-quality;
  text-rendering: geometricPrecision;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  will-change: transform;
  contain: layout style paint;
}

/* Optimize text rendering during transforms */
.transform-quality {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: normal;
  font-optical-sizing: auto;
}

/* Add these new styles for smooth post transitions */
.post-transition {
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform, width, left, top;
}

/* Add a class for width transitions specifically */
.width-transition {
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Update the transform-quality class */
.transform-quality {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  transform-style: preserve-3d;
  will-change: transform;
}

/* Add a class for the container transitions */
.canvas-container-transition {
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Add these new animations for border pulsing */
@keyframes borderPulseLight {
  0% {
    border-color: rgba(147, 197, 253, 0.4); /* blue-300 with 40% opacity */
  }
  50% {
    border-color: rgba(147, 197, 253, 0.8); /* blue-300 with 80% opacity */
  }
  100% {
    border-color: rgba(147, 197, 253, 0.4); /* blue-300 with 40% opacity */
  }
}

@keyframes borderPulseDark {
  0% {
    border-color: rgba(71, 85, 105, 0.4); /* slate-600 with 40% opacity */
  }
  50% {
    border-color: rgba(71, 85, 105, 0.8); /* slate-600 with 80% opacity */
  }
  100% {
    border-color: rgba(71, 85, 105, 0.4); /* slate-600 with 40% opacity */
  }
}

/* Update existing editing glow classes */
.editing-glow-light {
  box-shadow: 0 0 15px rgba(147, 197, 253, 0.15); /* blue-300 with 15% opacity */
}

.editing-glow-dark {
  box-shadow: 0 0 15px rgba(71, 85, 105, 0.15); /* slate-600 with 15% opacity */
}

/* Update existing transform-quality class */
.transform-quality {
  transform: translateZ(0);
  backface-visibility: hidden;
  will-change: transform;
  contain: layout style paint;
}

/* Add new minimal transition classes */
.post-width-transition {
  transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: width;
}

.post-position-transition {
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
}

/* Update the post container styles */
.linkedin-post-container {
  position: relative;
  transform: translateZ(0);
  backface-visibility: hidden;
  will-change: transform;
  contain: layout style paint;
}

/* Add styles for masonry transitions */
.masonry-container {
  contain: layout style paint;
  transform: translateZ(0);
  transition: none;
}

.masonry-grid {
  transition: none;
}

/* Remove any existing transition that might interfere */
.linkedin-post-mockup {
  transition: none;
}

/* Add specific transition only for width changes */
.post-resizing {
  transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: width;
  /* Prevent any other transitions */
  transition-property: width;
}

/* Add a class for smooth position changes */
.post-moving {
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
  /* Prevent any other transitions */
  transition-property: transform;
}

/* Optimize rendering during transitions */
.optimized-transitions {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateZ(0);
  backface-visibility: hidden;
}

/* Remove all previous transition classes and replace with these minimal ones */

/* Base styles for posts */
.linkedin-post-mockup {
  transform: translateZ(0);
  backface-visibility: hidden;
  transition: none; /* Remove default transitions */
}

/* Clean masonry transitions */
.masonry-container {
  contain: layout style paint;
  transform: translateZ(0);
  transition: none;
}

.masonry-grid {
  transition: none;
}

/* Remove width-changing opacity effect */
.width-changing .linkedin-post-container {
  transition: none;
}

/* Remove any grid-related transitions */
.grid {
  transition: none !important;
}

.grid > * {
  transition: none !important;
}

/* Keep only essential rendering optimizations */
.zoom-quality {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateZ(0);
}

/* List view styles - no transitions */
.list-view-container {
  transition: none;
}

.list-post {
  transition: none !important;
}

.list-view-post {
  transition: none;
}

:root {
  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;
  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;
  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;
  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;
  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;
  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --ring: 222.2 84% 4.9%;
}

/* Add these styles before the .dark class definition */
@layer base {
  .dark {
    --tw-text-opacity: 1;
    --tw-text-color: 156 163 175; /* gray-400 RGB values - comfortable on eyes */
  }
  
  .dark * {
    color: rgb(var(--tw-text-color) / var(--tw-text-opacity)) !important;
  }
  
  /* Preserve colors for specific elements that shouldn't be overridden */
  .dark [class*="text-indigo-"],
  .dark [class*="text-blue-"],
  .dark [class*="text-green-"],
  .dark [class*="text-red-"],
  .dark [class*="text-yellow-"],
  .dark [class*="text-amber-"],
  .dark [class*="text-purple-"] {
    color: inherit !important;
  }
}

/* Rest of your existing dark mode styles */
.dark {
  /* Base Background Colors */
  --bg-primary: #121212;      /* Main background */
  --bg-secondary: #1E1E1E;    /* Secondary background */
  --bg-tertiary: #282828;     /* Tertiary background for cards/elements */
  --bg-elevated: #2C2C2C;     /* Elevated surfaces */
  
  /* Surface Colors */
  --surface-0: #201f1b;       /* Base surface */
  --surface-1: #282828;       /* First level surface */
  --surface-2: #323232;       /* Second level surface */
  --surface-3: #424242;       /* Third level surface */
  
  /* Text Colors */
  --text-primary: #9CA3AF;    /* Updated to gray-400 for comfortable reading */
  --text-secondary: #A0A0A0;  /* Secondary text */
  --text-tertiary: #6B6B6B;   /* Tertiary text */
  --text-disabled: #6E6E6E;   /* Disabled text */
  
  /* Border Colors */
  --border-primary: #404040;  /* Primary borders */
  --border-secondary: #333333; /* Secondary borders */
  
  /* Accent Colors */
  --accent-blue: #4385BE;     /* Primary accent */
  --accent-green: #879A39;    /* Success states */
  --accent-red: #D14D41;      /* Error states */
  --accent-yellow: #D0A215;   /* Warning states */
  --accent-purple: #8B7EC8;   /* Special states */
  
  /* Hover States */
  --hover-bg: rgba(255, 255, 255, 0.05);  /* Hover background */
  --active-bg: rgba(255, 255, 255, 0.1);  /* Active background */
  
  /* Overlay Colors */
  --overlay-light: rgba(255, 255, 255, 0.05);
  --overlay-medium: rgba(255, 255, 255, 0.08);
  --overlay-heavy: rgba(0, 0, 0, 0.4);
  color: var(--text-primary); /* Global text color for dark mode */
}

/* Update specific component styles */
.dark .canvas-container {
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.dark .canvas-card {
  background-color: var(--bg-tertiary);
  border-color: var(--border-primary);
}

.dark .canvas-header {
  background-color: var(--bg-secondary);
  border-bottom-color: var(--border-secondary);
}

.dark .canvas-toolbar {
  background-color: var(--bg-elevated);
  border-color: var(--border-secondary);
}

/* Hover and Active States */
.dark .hover-element:hover {
  background-color: var(--hover-bg);
}

.dark .active-element:active {
  background-color: var(--active-bg);
}

/* Input Fields */
.dark .input-field {
  background-color: var(--bg-elevated);
  border-color: var(--border-primary);
  color: var(--text-primary);
}

.dark .input-field:focus {
  border-color: var(--accent-blue);
  background-color: var(--bg-tertiary);
}

/* Buttons */
.dark .button-primary {
  background-color: var(--accent-blue);
  color: var(--text-primary);
}

.dark .button-secondary {
  background-color: var(--bg-elevated);
  border-color: var(--border-primary);
  color: var(--text-primary);
}

/* Scrollbar Styling */
.dark .custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.dark .custom-scrollbar::-webkit-scrollbar-track {
  background: var(--bg-secondary);
}

.dark .custom-scrollbar::-webkit-scrollbar-thumb {
  background: var(--border-primary);
  border-radius: 4px;
}

.dark .custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--border-secondary);
}

/* Add these new styles for the Quill editor */
.custom-quill {
  border: none !important;
}

.custom-quill .ql-toolbar {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding: 12px 0 !important;
  border-radius: 0 !important;
  background: transparent !important;
}

.dark .custom-quill .ql-toolbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.custom-quill .ql-container {
  border: none !important;
  font-family: inherit !important;
  font-size: inherit !important;
}

.custom-quill .ql-editor {
  padding: 24px 0 12px !important;
  font-family: inherit !important;
  font-size: inherit !important;
}

/* Style Quill toolbar buttons */
.custom-quill .ql-toolbar button {
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.custom-quill .ql-toolbar button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.dark .custom-quill .ql-toolbar button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Style Quill toolbar button icons */
.custom-quill .ql-toolbar .ql-stroke {
  stroke: #6b7280 !important;
}

.custom-quill .ql-toolbar .ql-fill {
  fill: #6b7280 !important;
}

.dark .custom-quill .ql-toolbar .ql-stroke {
  stroke: #9ca3af !important;
}

.dark .custom-quill .ql-toolbar .ql-fill {
  fill: #9ca3af !important;
}

/* Style active state of toolbar buttons */
.custom-quill .ql-toolbar button.ql-active,
.custom-quill .ql-toolbar .ql-picker-label.ql-active {
  background-color: rgba(0, 0, 0, 0.05);
}

.dark .custom-quill .ql-toolbar button.ql-active,
.dark .custom-quill .ql-toolbar .ql-picker-label.ql-active {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Style dropdowns */
.custom-quill .ql-toolbar .ql-picker {
  height: 28px;
}

.custom-quill .ql-toolbar .ql-picker-label {
  padding: 4px 8px;
  border: none !important;
  color: #6b7280 !important;
}

.dark .custom-quill .ql-toolbar .ql-picker-label {
  color: #9ca3af !important;
}

/* Remove borders from picker options */
.custom-quill .ql-toolbar .ql-picker-options {
  border: none !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  border-radius: 6px !important;
  padding: 4px !important;
}

.dark .custom-quill .ql-toolbar .ql-picker-options {
  background-color: #1f2937 !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.18) !important;
}

/* Style picker items */
.custom-quill .ql-toolbar .ql-picker-item {
  padding: 4px 4px !important;
  border-radius: 4px !important;
}

.custom-quill .ql-toolbar .ql-picker-item:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.dark .custom-quill .ql-toolbar .ql-picker-item:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

/* Add these styles to ensure solid white background for Quill toolbar */
.custom-quill .ql-toolbar {
  background-color: white !important;
}

.dark .custom-quill .ql-toolbar {
  background-color: rgb(30 41 59) !important; /* slate-800 */
}

/* Update the toolbar styles to justify content */
.custom-quill .ql-toolbar {
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  padding: 8px 12px !important;
  position: sticky !important;
  top: 40px !important;
  z-index: 40 !important;
  margin: 0 0 16px 0 !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
}

/* Adjust the format groups spacing */
.custom-quill .ql-toolbar .ql-formats {
  display: flex !important;
  align-items: center !important;
  margin-right: 0 !important; /* Remove right margin since we're using space-between */
  margin-bottom: 0 !important;
  gap: 4px !important;
}

/* Add some space between format groups when they wrap */
.custom-quill .ql-toolbar .ql-formats:not(:last-child) {
  margin-right: 8px !important;
}

/* Keep buttons and pickers aligned */
.custom-quill .ql-toolbar button,
.custom-quill .ql-toolbar .ql-picker {
  margin: 0 !important;
  padding: 3px !important;
  height: 24px !important;
}

@keyframes tilt {
  0%, 50%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0.5deg);
  }
  75% {
    transform: rotate(-0.5deg);
  }
}

.animate-tilt {
  animation: tilt 10s infinite linear;
}

/* Replace the existing dotted-background styles */
.dotted-background {
  background-image: 
    linear-gradient(90deg, rgba(99, 102, 241, 0.12) 1px, transparent 1px),
    linear-gradient(0deg, rgba(99, 102, 241, 0.12) 1px, transparent 1px);
  background-size: 32px 32px;
  background-position: 0 0;
  background-color: transparent;
  position: relative;
  animation: line-shift 18s infinite linear;
}

.dotted-background::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.005) 15%,
    rgba(255, 255, 255, 0.005) 85%,
    transparent 100%
  ),
  linear-gradient(
    to bottom,
    transparent 0%,
    rgba(255, 255, 255, 0.005) 15%,
    rgba(255, 255, 255, 0.005) 85%,
    transparent 100%
  );
  pointer-events: none;
}

.dotted-background::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.4) 0%,
    transparent 5%,
    transparent 95%,
    rgba(255, 255, 255, 0.4) 100%
  ),
  linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.4) 0%,
    transparent 5%,
    transparent 95%,
    rgba(255, 255, 255, 0.4) 100%
  );
  pointer-events: none;
  opacity: 0.05;
}

/* Dark mode support - made lines more subtle and blended */
.dark .dotted-background {
  background-image: 
    linear-gradient(90deg, rgba(255, 255, 255, 0.07) 1px, transparent 1px),
    linear-gradient(0deg, rgba(255, 255, 255, 0.07) 1px, transparent 1px);
}

.dark .dotted-background::before {
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(0, 0, 0, 0.1) 15%,
    rgba(0, 0, 0, 0.1) 85%,
    transparent 100%
  ),
  linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.1) 15%,
    rgba(0, 0, 0, 0.1) 85%,
    transparent 100%
  );
}

.dark .dotted-background::after {
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.4) 0%,
    transparent 5%,
    transparent 95%,
    rgba(0, 0, 0, 0.4) 100%
  ),
  linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4) 0%,
    transparent 5%,
    transparent 95%,
    rgba(0, 0, 0, 0.4) 100%
  );
  opacity: 0.05;
}

/* Slow down the animation slightly */
@keyframes line-shift {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 32px 32px;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dotted-background {
    background-size: 24px 24px;
  }
  
  @keyframes line-shift {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 24px 24px;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  animation: gradient 8s linear infinite;
}



@keyframes chrome-gradient {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.animate-gradient-chrome {
  animation: chrome-gradient 8s ease infinite;
  background-size: 200% auto;
  background-image: linear-gradient(
    -45deg,
    #c084fc 0%,      /* purple-400 */
    #a855f7 15%,     /* purple-500 */
    #8b5cf6 30%,     /* violet-500 */
    #6366f1 45%,     /* indigo-500 */
    #3b82f6 60%,     /* blue-500 */
    #38bdf8 75%,     /* sky-400 */
    #22d3ee 90%,     /* cyan-400 */
    #c084fc 100%     /* purple-400 */
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  display: inline-block;
  filter: drop-shadow(0 0 0.5px rgba(255, 255, 255, 0.1));
  padding: 40px;
  margin: -40px;
}

.animate-gradient-chrome::after,
.animate-gradient-chrome::before {
  content: attr(data-text);
  position: absolute;
  left: 50%;
  top: 50%;
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  transform: translate(-50%, -50%);
  background: inherit;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.animate-gradient-chrome::after {
  filter: blur(25px) brightness(1.3);
  opacity: 0.3;
  z-index: -1;
}

.animate-gradient-chrome::before {
  filter: blur(40px) brightness(1.2);
  opacity: 0.2;
  z-index: -2;
}

/* Dark mode adjustments */
.dark .animate-gradient-chrome {
  filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.15));
  background-image: linear-gradient(
    -45deg,
    #e9d5ff 0%,      /* purple-200 */
    #d8b4fe 15%,     /* purple-300 */
    #c4b5fd 30%,     /* violet-300 */
    #a5b4fc 45%,     /* indigo-300 */
    #93c5fd 60%,     /* blue-300 */
    #7dd3fc 75%,     /* sky-300 */
    #67e8f9 90%,     /* cyan-300 */
    #e9d5ff 100%     /* purple-200 */
  );
}

.dark .animate-gradient-chrome::after {
  filter: blur(25px) brightness(1.4);
  opacity: 0.35;
}

.dark .animate-gradient-chrome::before {
  filter: blur(40px) brightness(1.3);
  opacity: 0.25;
}

.chromatic-text {
  text-shadow: 2px 0 0 rgba(0, 255, 255, 0.2), -2px 0 0 rgba(255, 0, 255, 0.2);
  transition: all 0.3s ease;
}

.chromatic-text:hover {
  text-shadow: 3px 0 0 rgba(0, 255, 255, 0.3), -3px 0 0 rgba(255, 0, 255, 0.3);
}

/* Apple-style gradient text effect */
.gradient-text {
  background: linear-gradient(
    to right,
    #3b82f6, /* blue */
    #8b5cf6, /* purple */
    #ec4899, /* pink */
    #f97316  /* orange */
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  display: inline-block;
}

.gradient-text::after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  filter: blur(12px);
  opacity: 0.7;
  z-index: -1;
}

.gradient-text-animated {
  background-size: 200% auto;
  animation: shine 4s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

/* Dark mode adjustments */
.dark .gradient-text {
  filter: brightness(1.2);
}

.dark .gradient-text::after {
  filter: blur(16px);
  opacity: 0.8;
}

@keyframes border-flow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-border-flow {
  animation: border-flow 4s ease infinite;
  background-size: 300% 300%;
}

/* Add this CSS-based noise effect instead */
.noise {
  position: relative;
  isolation: isolate;
}

.noise::after {
  content: '';
  position: absolute;
  inset: 0;
  opacity: 0.05;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 256 256' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.8' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  z-index: -1;
  pointer-events: none;
}

.dark .noise::after {
  opacity: 0.1;
}

/* Custom Scrollbar Styles */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 3px;
}

/* Dark mode */
.dark {
  --scrollbar-thumb: rgb(64, 64, 64);
  --scrollbar-track: rgb(38, 38, 38);
}

/* Light mode */
:root {
  --scrollbar-thumb: rgb(212, 212, 212);
  --scrollbar-track: rgb(243, 244, 246);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background-size: 200% auto;
  animation: gradient 8s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-shadow-sm {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Ensure gradient text works across browsers */
@supports (-webkit-background-clip: text) or (background-clip: text) {
  .animate-gradient {
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
}

.transform.hover\:scale-102:hover {
  transform: scale(1.02);
}


/* Remove the gradient animation */
.transform.hover\:scale-102:hover {
  transform: scale(1.02);
}

/* Add better text rendering */
.text-gradient {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Base styles for mobile height handling */
:root {
  --vh: 1vh;
  --mobile-height: 100vh;
  --safe-bottom: 60px;
}

html {
  height: 100%;
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: fixed;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .App {
    height: calc(var(--vh, 1vh) * 100);
    min-height: var(--mobile-height);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  /* Main content area scrolling */
  .mobile-scroll-container {
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: contain;
    position: relative;
    /* Match MobileBar mx-2 (0.5rem) */
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-bottom: max(var(--safe-bottom), env(safe-area-inset-bottom, 16px));
  }

  /* Handle keyboard appearance */
  .keyboard-visible .mobile-scroll-container {
    height: calc(100vh - var(--keyboard-height, 0px));
    padding-bottom: 16px;
  }

  /* Mobile navigation bar */
  .mobile-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
    background: var(--bg-primary);
    padding-bottom: env(safe-area-inset-bottom, 0px);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 0.5rem;
  }
}

/* Prevent pull-to-refresh on iOS */
@supports (-webkit-touch-callout: none) {
  body {
    overscroll-behavior-y: none;
  }
  
  .mobile-scroll-container {
    overscroll-behavior-y: contain;
  }
}

/* Ensure proper sizing for inputs on mobile */
@media screen and (max-width: 767px) {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  textarea {
    font-size: 16px !important;
    /* Remove the extra margin at bottom of inputs */
    margin-bottom: 0;
  }
}

/* Add specific handling for bottom navigation */
.mobile-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background: var(--bg-primary);
  padding-bottom: env(safe-area-inset-bottom, 0px);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animate-gradient {
    background-size: 300% 300%;
    animation: gradient 8s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

