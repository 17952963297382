/* Custom Scrollbar Styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 2px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.7);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Minimal scrollbar styles */
.minimal-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.minimal-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.minimal-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(156, 163, 175, 0.6);
  border-radius: 2px;
}

.minimal-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(156, 163, 175, 0.8);
}

/* Hide scrollbar by default */
.minimal-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Show scrollbar on hover */
.minimal-scrollbar:hover {
  scrollbar-color: rgba(156, 163, 175, 0.6) transparent;
}

/* For Webkit browsers */
.minimal-scrollbar:hover::-webkit-scrollbar-thumb {
  background: rgba(156, 163, 175, 0.6);
}

/* Ultra minimal scrollbar - Add these new styles */
.ultra-minimal-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.2) transparent;
}

.ultra-minimal-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.ultra-minimal-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.ultra-minimal-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(156, 163, 175, 0.2);
  border-radius: 1.5px;
}

.ultra-minimal-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(156, 163, 175, 0.5);
}

/* Show scrollbar only on hover */
.ultra-minimal-scrollbar:hover {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
}

/* For Webkit browsers */
.ultra-minimal-scrollbar:hover::-webkit-scrollbar-thumb {
  background: rgba(156, 163, 175, 0.5);
}

/* Add these new styles at the end of the file */
.ultra-minimal-scrollbar-horizontal {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.2) transparent;
  overflow-x: auto;
  overflow-y: hidden;
}

.ultra-minimal-scrollbar-horizontal::-webkit-scrollbar {
  height: 3px;  /* For horizontal scrollbar */
  width: 3px;
}

.ultra-minimal-scrollbar-horizontal::-webkit-scrollbar-track {
  background: transparent;
}

.ultra-minimal-scrollbar-horizontal::-webkit-scrollbar-thumb {
  background: rgba(156, 163, 175, 0.2);
  border-radius: 1.5px;
}

.ultra-minimal-scrollbar-horizontal::-webkit-scrollbar-thumb:hover {
  background: rgba(156, 163, 175, 0.5);
}

/* Show scrollbar only on hover */
.ultra-minimal-scrollbar-horizontal:hover {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
}

/* Make scrollbar overlay content without affecting layout */
.ultra-minimal-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-gutter: stable both-edges; /* Prevent layout shift */
  overflow-y: overlay; /* Use overlay instead of auto/scroll */
}

/* For Webkit browsers (Chrome, Safari, etc.) */
.ultra-minimal-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.ultra-minimal-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.ultra-minimal-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 4px;
}

/* For dark mode */
.dark .ultra-minimal-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.3);
}

/* Hide scrollbar when not hovering */
.ultra-minimal-scrollbar:not(:hover)::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Show scrollbar on hover */
.ultra-minimal-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
}

/* Existing styles */
.ultra-minimal-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.ultra-minimal-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.ultra-minimal-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.3);
  border-radius: 3px;
}

/* Add new specific class for welcome view */
.welcome-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.welcome-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.welcome-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.3);
  border-radius: 4px;
}

/* Dark mode styles for welcome scrollbar */
.dark .welcome-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Custom scrollbar styling */
.canvas-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.canvas-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.canvas-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 3px;
  transition: background-color 0.2s ease;
}

.canvas-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.7);
}

/* For Firefox */
.canvas-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
}

/* For dark mode */
.dark .canvas-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.3);
}

.dark .canvas-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.5);
}

.dark .canvas-scrollbar {
  scrollbar-color: rgba(156, 163, 175, 0.3) transparent;
}

/* Add these styles for the popup scrollbar */
.popup-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

.popup-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.popup-scrollbar::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: 4px;
}

.popup-scrollbar::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 4px;
  border: 2px solid var(--scrollbar-track);
}

.popup-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}

/* Dark mode */
.dark .popup-scrollbar {
  --scrollbar-thumb: #333333;
  --scrollbar-track: #1a1a1a;
}

/* Light mode */
.popup-scrollbar {
  --scrollbar-thumb: #d1d5db;
  --scrollbar-track: #f3f4f6;
}
