/*==============================================
 * FONT IMPORTS
 *=============================================*/
@import '@fontsource/inter/300.css';
@import '@fontsource/inter/400.css';
@import '@fontsource/inter/500.css';
@import '@fontsource/inter/700.css';

/*==============================================
 * THEME VARIABLES
 *=============================================*/
:root {
  /* Font Families */
  --font-family-base: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-family-code: 'Inter', 'Roboto Mono', 'Source Code Pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;
  
  /* Colors - Light Mode */
  --color-bg-light: #ffffff;
  --color-text-light: #334155;
  --color-border-light: #e5e7eb;
  --color-link-light: #3b82f6;
  --color-code-bg-light: #f3f4f6;
  --color-blockquote-light: #64748b;
  
  /* Colors - Dark Mode */
  --color-bg-dark: #151515;
  --color-text-dark: #cbd5e1;
  --color-border-dark: rgba(255, 255, 255, 0.05);
  --color-link-dark: #60a5fa;
  --color-code-bg-dark: #1f2937;
  --color-blockquote-dark: #94a3b8;
  
  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  
  /* Font Sizes */
  --text-xs: 11px;
  --text-sm: 13px;
  --text-base: 14px;
  --text-lg: 16px;
}

/*==============================================
 * BASE CHAT STYLES
 *=============================================*/
.chat-bubble {
  width: 100%;
  border-radius: 0.75rem;
  overflow-wrap: break-word;
}

.chat-bubble.light {
  background-color: var(--color-bg-light);
  color: var(--color-text-light);
}

.chat-bubble.dark {
  background-color: var(--color-bg-dark);
  color: var(--color-text-dark);
}

/*==============================================
 * MARKDOWN CONTENT STYLES
 *=============================================*/
.markdown-content {
  font-family: var(--font-family-base);
  font-size: var(--text-sm);
  line-height: 1.6;
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: inherit;
  margin: 0;
  padding: 0;
}

/* Reset margins and paddings for all direct children */
.markdown-content > * {
  margin: 0;
  padding: 0;
}

/* Text Content */
.markdown-content p {
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.6;
}

.markdown-content p + p {
  margin-top: var(--spacing-md);
}

/* Headings */
.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  font-weight: 600;
  line-height: 1.3;
  margin: var(--spacing-xl) 0 var(--spacing-sm) 0;
  padding: 0;
}

/* First heading shouldn't have top margin */
.markdown-content > h1:first-child,
.markdown-content > h2:first-child,
.markdown-content > h3:first-child {
  margin-top: 0;
}

/* Font sizes for headings */
.markdown-content h1 { 
  font-size: var(--text-lg);
  margin-top: 2.5rem;  /* Even bigger margin for main sections */
}

.markdown-content h2 { 
  font-size: var(--text-base);
  margin-top: 2rem;
}

.markdown-content h3 { 
  font-size: var(--text-sm);
  margin-top: 1.5rem;
}

/* When headings follow each other, reduce the spacing */
.markdown-content h1 + h2,
.markdown-content h2 + h3 {
  margin-top: 0.5rem;
}

/* Content immediately following headings */
.markdown-content h1 + p,
.markdown-content h2 + p,
.markdown-content h3 + p,
.markdown-content h1 + ul,
.markdown-content h2 + ul,
.markdown-content h3 + ul,
.markdown-content h1 + ol,
.markdown-content h2 + ol,
.markdown-content h3 + ol {
  margin-top: 0.3rem;
}

/* Line breaks */
.markdown-content br {
  display: block;
  content: "";
  margin: var(--spacing-sm) 0;
}

/* Remove extra space when br follows a heading */
.markdown-content h1 + br,
.markdown-content h2 + br,
.markdown-content h3 + br {
  margin-top: 0.3rem;
}

/* Add space after br when followed by a heading */
.markdown-content br + h1 {
  margin-top: 1.5rem;
}

.markdown-content br + h2 {
  margin-top: 1.2rem;
}

.markdown-content br + h3 {
  margin-top: 1rem;
}

/* Add space between paragraphs and lists */
.markdown-content p + br,
.markdown-content ul + br,
.markdown-content ol + br {
  margin-top: 0.5rem;
}

/* Major section spacing - significantly increased */
.markdown-content *:not(h1, h2, h3) + h1 {
  margin-top: 2rem;  /* Large gap before new major sections */
}

.markdown-content *:not(h1, h2, h3) + h2 {
  margin-top: 1.5rem;  /* Medium gap before new subsections */
}

.markdown-content *:not(h1, h2, h3) + h3 {
  margin-top: 1rem;  /* Smaller gap before sub-subsections */
}

/* Lists */
.markdown-content ul,
.markdown-content ol {
  margin: var(--spacing-sm) 0;
  padding-left: var(--spacing-lg);
  line-height: 1.6;
}

.markdown-content ul { list-style-type: disc; }
.markdown-content ol { list-style-type: decimal; }

.markdown-content li {
  margin: var(--spacing-xs) 0;
  padding: 0;
  display: list-item;
  line-height: 1.6;
}

.markdown-content li p {
  display: inline;
  margin: var(--spacing-xs) 0;
}

/* Numbered Lists - Specific Adjustments */
.markdown-content ol {
  counter-reset: item;
  list-style-position: outside;
}

.markdown-content ol > li {
  display: list-item;
  margin: 0;
  padding: 0;
}

/* Remove space after last list item */
.markdown-content ul > li:last-child,
.markdown-content ol > li:last-child {
  margin-bottom: 0;
}

/* Ensure no extra space when list items contain paragraphs */
.markdown-content li > p:first-child {
  margin-top: 0;
}

.markdown-content li > p:last-child {
  margin-bottom: 0;
}

/* Ensure headers followed by lists have minimal spacing */
.markdown-content h1 + ul,
.markdown-content h1 + ol,
.markdown-content h2 + ul,
.markdown-content h2 + ol,
.markdown-content h3 + ul,
.markdown-content h3 + ol,
.markdown-content h4 + ul,
.markdown-content h4 + ol {
  margin-top: 0;
}

/* Ensure headers followed by paragraphs have minimal spacing */
.markdown-content h1 + p,
.markdown-content h2 + p,
.markdown-content h3 + p,
.markdown-content h4 + p {
  margin-top: 0;
}

/* Code Blocks */
.markdown-content code,
.markdown-content pre {
  font-family: var(--font-family-code);
  font-size: var(--text-xs);
  white-space: pre-wrap;
  word-break: break-word;
  margin: 0.2rem 0;
  padding: 0;
  line-height: 1.2;
}

.markdown-pre {
  margin: var(--spacing-sm) 0;
  padding: var(--spacing-md);
  border-radius: 0.375rem;
  overflow-x: auto;
}

.light .markdown-pre {
  background-color: var(--color-code-bg-light);
}

.dark .markdown-pre {
  background-color: var(--color-code-bg-dark);
}

/* Links */
.markdown-content a {
  text-decoration: underline;
  color: inherit;
}

.light .markdown-content a {
  color: var(--color-link-light);
}

.dark .markdown-content a {
  color: var(--color-link-dark);
}

/* Blockquotes */
.markdown-content blockquote {
  margin: var(--spacing-md) 0;
  padding: var(--spacing-sm) var(--spacing-md);
  border-left: 4px solid;
  line-height: 1.6;
}

.light .markdown-content blockquote {
  border-color: var(--color-blockquote-light);
  color: var(--color-blockquote-light);
}

.dark .markdown-content blockquote {
  border-color: var(--color-blockquote-dark);
  color: var(--color-blockquote-dark);
}

/* Table Styles */
.markdown-content table {
  width: 100%;
  border-collapse: separate;
  caption-side: bottom;
  table-layout: auto;
  margin: var(--spacing-sm) 0;
  border-radius: 0.375rem;
  border-spacing: 0;
  overflow: hidden;
}

.markdown-content th {
  font-weight: 500;
  text-align: left;
  padding: var(--spacing-sm) var(--spacing-md);
  color: inherit;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.6;
  border: 1px solid;
}

.markdown-content td {
  padding: var(--spacing-sm) var(--spacing-md);
  vertical-align: middle;
  line-height: 1.6;
  white-space: normal;
  word-wrap: break-word;
  border: 1px solid;
}

/* Light mode table styles */
.light .markdown-content table {
  background-color: #fff;
}

.light .markdown-content th {
  color: #64748b;
  background-color: #f8fafc;
  border-color: #e2e8f0;
}

.light .markdown-content td {
  color: #334155;
  border-color: #e2e8f0;
}

.light .markdown-content tr:hover {
  background-color: #f8fafc;
}

/* Dark mode table styles */
.dark .markdown-content table {
  background-color: #151515;
}

.dark .markdown-content th {
  color: #94a3b8;
  background-color: #1e293b;
  border-color: #334155;
}

.dark .markdown-content td {
  color: #e2e8f0;
  border-color: #334155;
}

.dark .markdown-content tr:hover {
  background-color: rgba(255, 255, 255, 0.02);
}

/* Remove double borders */
.markdown-content tr th,
.markdown-content tr td {
  border-right-width: 0;
  border-top-width: 0;
}

.markdown-content tr th:last-child,
.markdown-content tr td:last-child {
  border-right-width: 1px;
}

.markdown-content tr:first-child th {
  border-top-width: 1px;
}

/* Corner radius for first row header cells */
.markdown-content tr:first-child th:first-child {
  border-top-left-radius: 0.375rem;
}

.markdown-content tr:first-child th:last-child {
  border-top-right-radius: 0.375rem;
}

/* Corner radius for last row cells */
.markdown-content tr:last-child td:first-child {
  border-bottom-left-radius: 0.375rem;
}

.markdown-content tr:last-child td:last-child {
  border-bottom-right-radius: 0.375rem;
}

/* Ensure hover states don't break the rounded corners */
.light .markdown-content tr:hover td,
.dark .markdown-content tr:hover td {
  position: relative;
  z-index: 1;
}

/* Responsive table styles */
@media (max-width: 640px) {
  .markdown-content table {
    display: table;
    width: 100%;
    margin: 0;
    border-radius: 0.375rem;
    overflow: hidden;
  }

  .markdown-content th,
  .markdown-content td {
    min-width: auto;
    padding: var(--spacing-xs) var(--spacing-sm);
  }
}

/* Images */
.markdown-content img {
  max-width: 100%;
  height: auto;
  margin: 0.2rem 0;
}

/*==============================================
 * UTILITY CLASSES
 *=============================================*/
.message-content {
  overflow: hidden;
  padding: var(--spacing-sm) 0;
}

.markdown-list-container {
  overflow: hidden;
}

/*==============================================
 * ANIMATION CLASSES
 *=============================================*/
.transition-opacity {
  transition: opacity 200ms ease-in-out;
}

.transition-all {
  transition: all 200ms ease-in-out;
}

/*==============================================
 * LINKEDIN POST STYLES
 *=============================================*/
.linkedin-post {
  border-radius: 0.375rem;
  padding: var(--spacing-md);
  margin: var(--spacing-md) 0;
  background-color: var(--color-bg-light);
}

.dark .linkedin-post {
  background-color: var(--color-bg-dark);
}

/* Horizontal rule */
.markdown-content hr {
  margin: 0.2rem 0;
  border: 0;
  border-top: 1px solid var(--color-border-light);
}

.markdown-list-item {
  line-height: 1.6;
  color: inherit;
}
